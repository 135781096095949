<template>
    <div class="view pa24">
        <div class="mb20">
            <el-tabs v-model="contentType" @tab-click="handleClick">
                <el-tab-pane label="会员类型" name="0"></el-tab-pane>
                <el-tab-pane label="会员职务" name="1"></el-tab-pane>
            </el-tabs>
        </div>
        <div v-show="contentType === '0'">
            <div class="d-flex">
                <div>
                    <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                    <el-button type="primary" @click="searchFun">搜索</el-button>
                </div>
                <div style="margin-left: auto">
                    <el-button class="ma" type="primary" @click="showAddTypeModel">新增会员类型</el-button>
                </div>
            </div>
            <commonTable :tableData="tableData" :loading="loading" :paginationVisible="false">
                <template v-slot:table>
                    <el-table-column type="index" align="center" label="序号" />
                    <el-table-column prop="oldLevelName" align="center" label="会员类型(关联类型/类型名称)" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span class="fwbold">{{ scope.row.oldLevelName }}</span> <i class="el-icon-sort"
                                style="transform:rotate(90deg)"></i> {{
                scope.row.levelName }}
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="levelName" align="center" label="显示类型名称" show-overflow-tooltip /> -->
                    <el-table-column prop="linkLevelName" align="center" width="150" label="级别" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.levelSort }}级
                        </template>
                    </el-table-column>
                    <el-table-column prop="statusTitle" align="center" label="状态" width="150" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" @change="disableFun(scope.row, $event)"
                                :active-value="0" :inactive-value="1">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="150">
                        <template slot-scope="scope">
                            <el-button class="mr10" type="text" @click="showUpdateTypeModel(scope.row)">编辑</el-button>
                            <customPopconfirm confirm-button-text="确定" cancel-button-text="取消"
                                @confirm="delFun(scope.row.levelId)" icon="el-icon-info" icon-color="red"
                                title="确定要删除这条类型？">
                                <el-button slot="reference" style="color: #F56C6C" type="text">删除</el-button>
                            </customPopconfirm>
                            <!-- <customPopconfirm confirm-button-text="确定" cancel-button-text="取消" v-if="scope.row.status === 0"
                                        @confirm="disableFun(scope.row, 1)" icon="el-icon-info" icon-color="red" title="确定要禁用这条分类？">
                                        <el-button slot="reference" style="color: #F56C6C" type="text">禁用</el-button>
                                    </customPopconfirm>
                                    <customPopconfirm confirm-button-text="确定" cancel-button-text="取消" v-if="scope.row.status === 1"
                                        @confirm="disableFun(scope.row, 0)" icon="el-icon-info" title="确定要启用这条分类？">
                                        <el-button slot="reference" type="text">启用</el-button>
                                    </customPopconfirm> -->
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
        </div>
        <div v-show="contentType === '1'">
            <div class="d-flex">
                <div>
                    <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                    <el-button type="primary" @click="searchFun">搜索</el-button>
                </div>
                <div style="margin-left: auto">
                    <el-button class="ma" type="primary" @click="showAddJobModel">新增会员职务</el-button>
                </div>
            </div>
            <commonTable :tableData="tableData" :loading="loading" :paginationVisible="false">
                <template v-slot:table>
                    <el-table-column type="index" align="center" label="序号" />
                    <el-table-column prop="oldLevelName" align="center" label="会员职务(关联职务/职务名称)" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span class="fwbold">{{ scope.row.oldLevelName }}</span> <i class="el-icon-sort"
                                style="transform:rotate(90deg)"></i> {{
                scope.row.levelName }}
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="levelName" align="center" label="显示职务名称" show-overflow-tooltip /> -->
                    <el-table-column prop="linkLevelName" align="center" label="分销等级" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.linkLevelName || "暂无关联" }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="linkLevelName" align="center" width="80" label="级别" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.levelSort }}级
                        </template>
                    </el-table-column>
                    <el-table-column prop="pointGive" align="center" width="150" label="赠送积分" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-popover placement="top-start" width="220" trigger="hover"
                                :content="'推荐成为该职务赠送 ' + scope.row.pointShare + ' 积分 成为该职务赠送 ' + scope.row.pointGive + ' 积分'">
                                <el-button slot="reference" type="text">查看详细</el-button>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column prop="statusTitle" align="center" label="状态" width="120" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" @change="disableFun(scope.row, $event)"
                                :active-value="0" :inactive-value="1">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" @click="relationFun(scope.row)" v-if="showMember">关联</el-button>
                            <el-button class="mr10" type="text" @click="showUpdateJobModel(scope.row)">编辑</el-button>
                            <customPopconfirm confirm-button-text="确定" cancel-button-text="取消"
                                @confirm="delFun(scope.row.levelId)" icon="el-icon-info" icon-color="red"
                                title="确定要删除这条职务？">
                                <el-button slot="reference" style="color: #F56C6C" type="text">删除</el-button>
                            </customPopconfirm>
                            <el-button class="ml10" type="text" @click="setIntroduceFun(scope.row)">介绍</el-button>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
        </div>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="typeModelTitle"
            :visible.sync="typeShowModel" :destroy-on-close="true" width="500px" center>
            <el-form :model="typeFormData" :rules="typeRules" ref="typeFormData" label-width="130px">
                <el-row>
                    <el-col>
                        <el-form-item label="类型" prop="memberLevel">
                            <el-select v-model="typeFormData.memberLevel" class="w100p"
                                :disabled="formType === 'update'" placeholder="请选择">
                                <el-option v-for="(name, id) in memberLevel" :key="String(id)" :label="name"
                                    :value="String(id)">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="类型名称" prop="levelName">
                            <el-input v-model="typeFormData.levelName" placeholder="请输入类型名称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="级别" prop="levelSort">
                            <el-input type="number" class="suffixInput" title="数值越大，级别越高"
                                v-model="typeFormData.levelSort" placeholder="请输入级别">
                                <template slot="append">级</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-divider content-position="left">免费进驻权益</el-divider>
                <el-checkbox v-model="typeFormData.freeIntoCompany" label="企服大厅" border size="medium"></el-checkbox>
                <el-checkbox v-model="typeFormData.freeIntoShop" label="合作商家" border size="medium"></el-checkbox>
                <!-- <el-row>
                    <el-col>
                        <el-form-item label="免费进驻企服大厅" prop="freeIntoCompany">
                            <el-switch v-model="typeFormData.freeIntoCompany">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="免费进驻合作商家" prop="freeIntoShop">
                            <el-switch v-model="typeFormData.freeIntoShop">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row> -->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="typeShowModel = false">取 消</el-button>
                <el-button type="primary" @click="typeSubmit">提 交</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="jobModelTitle"
            :visible.sync="jobShowModel" :destroy-on-close="true" width="80%" center>
            <el-form :model="jobFormData" :rules="jobRules" ref="jobFormData" label-width="130px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="职务" prop="memberLevel">
                            <el-select class="w100p" v-model="jobFormData.memberLevel" :disabled="formType === 'update'"
                                placeholder="请选择">
                                <el-option v-for="(name, id) in memberJob" :key="String(id)" :label="name"
                                    :value="String(id)">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="职务名称" prop="levelName">
                            <el-input v-model="jobFormData.levelName" placeholder="请输入等级名称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="级别" prop="levelSort">
                            <!-- <el-input class="w240" placeholder="请输入级别" :disabled="formType === 'update'" v-model="formData.levelSort"
                            type="number" /> -->
                            <el-input type="number" class="suffixInput" title="数值越大，级别越高"
                                v-model="jobFormData.levelSort" placeholder="请输入级别">
                                <template slot="append">级</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="免费报名人数" prop="activityFreeNum">
                            <el-popover placement="bottom" width="295" trigger="hover" content="属于该职务会员可免费报名活动的人数">
                                <!-- <el-input type="number" slot="reference" v-model="jobFormData.activityFreeNum"
                                    placeholder="会员成员可免费报名活动人数">
                                </el-input> -->
                                <el-input-number slot="reference" v-model="jobFormData.activityFreeNum" :min="1"
                                    :max="1000" label="会员成员可免费报名活动人数"></el-input-number>
                            </el-popover>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-divider content-position="left">赠送积分权益</el-divider>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="成为职务" prop="pointGive">
                            <el-input-number v-model="jobFormData.pointGive" :min="1"
                                label="成为职务赠送积分"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="推送职务" prop="pointShare">
                            <el-input-number v-model="jobFormData.pointShare" :min="1"
                                label="推送职务赠送积分"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-divider content-position="left">职务介绍</el-divider>
                <el-form-item label="介绍" prop="content">
                    <quill-editor style="height: 100%" v-model="jobFormData.content" ref="myQuillEditor"
                        :options="editorOption" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="jobShowModel = false">取 消</el-button>
                <el-button type="primary" @click="jobSubmit">提 交</el-button>
            </span>
        </el-dialog>
        <!-- 关联分销 -->
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="关联分销等级"
            :visible.sync="showRelation" :destroy-on-close="true" width="400px" center>
            <el-form :model="relationData" :rules="relationRules" ref="jobFormData" label-width="110px">
                <el-form-item label="职务名称" prop="levelName">
                    {{ relationData.levelName }}
                </el-form-item>
                <el-form-item label="分销等级" prop="levelId">
                    <el-select class="w100p" v-model="relationData.levelId" clearable placeholder="请选择">
                        <el-option v-for="(row, index) in linkLevelData" :key="index" :label="row.levelName"
                            :value="row.levelId">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showRelation = false">取 消</el-button>
                <el-button type="primary" @click="relationSubmit">关 联</el-button>
            </span>
        </el-dialog>
        <!-- 职务介绍 -->
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="职务介绍"
            :visible.sync="showIntroduce" :destroy-on-close="true" width="700px" center>
            <div>
                <quill-editor style="height: 100%" v-model="introduce.content" ref="myQuillEditor"
                    :options="editorOption" />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showIntroduce = false">取 消</el-button>
                <el-button type="primary" @click="introduceSubmit">设 置</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写职务介绍'
var Align = Quill.import('attributors/style/align');
Align.whitelist = ['right', 'center', 'justify'];
Quill.register(Align, true);

import commonTable from "@/components/common/commonTable";
import { selectAllMemberLevel } from "@/api/member";
import { getByDictType, getAssociationMemberLevelList, setAssociationMemberLevel, setAssociationMemberLevelLink } from "@/api/associationMember";
import customPopconfirm from "@/components/common/customPopconfirm";
export default {
    name: "groupingTable",
    components: {
        commonTable,
        customPopconfirm,
        quillEditor
    },
    data() {
        return {
            editorOption: quillConfig,
            //列表配置
            loading: false, //表格加载
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            contentType: "0",
            associationId: 0,
            //编辑新增配置
            memberLevel: {},//会员类型
            memberJob: {},//会员职务
            typeModelTitle: "",
            typeShowModel: false,
            typeEquity: [],
            typeFormData: {
                levelName: "",
                memberLevel: "",
                levelSort: 0,
                freeIntoShop: false,
                freeIntoCompany: false,
            },
            typeRules: {
                levelName: {
                    required: true,
                    trigger: "blur",
                    message: "请输入类型名称",
                },
                memberLevel: {
                    required: true,
                    trigger: "chnage",
                    message: "请选择类型",
                },
            },
            jobModelTitle: "",
            jobShowModel: false,
            jobFormData: {
                levelName: "",
                memberLevel: "",
                activityFreeNum: 0,
                levelSort: 0,
                content: "",
                pointGive: 0,
                pointShare: 0,
            },
            jobRules: {
                levelName: {
                    required: true,
                    trigger: "blur",
                    message: "请输入职务名称",
                },
                memberLevel: {
                    required: true,
                    trigger: "chnage",
                    message: "请选择职务",
                },
            },
            formType: "",
            updateId: 0,
            statusData: { 0: "正常", 1: "禁用" },
            //关联分销等级配置
            relationData: {
                levelName: "",
                levelId: 0,
                memberLevelId: 0,
                linkId: 0,
                levelType: 1
            },
            relationRules: {

            },
            showRelation: false,
            linkLevelData: [],
            showMember: false,
            //设置介绍
            showIntroduce: false,
            introduce: {
                levelId: 0,
                content: ""
            }

        };
    },
    computed: {
        watchAssociation() {
            return this.$store.state.checkedAssociation
        }
    },
    watch: {
        watchAssociation(row) {
            this.associationId = row.associationId;
            this.getList();
        },
    },
    filters: {

    },
    created() {
        this.showMember = sessionStorage.getItem("showAssociationMember") === 'true' ? true : false;//是否显示会员管理
        this.associationId = JSON.parse(sessionStorage.getItem('checkedAssociation')).associationId;
        this.getInitData().then(() => {
            this.getList();
        })
    },
    methods: {
        /**@method 提交设置介绍 */
        introduceSubmit() {
            let parmas = {
                ...this.introduce,
                associationId: this.associationId,
                levelType: 1
            }
            setAssociationMemberLevel(parmas).then(res => {
                if (res.code === 200) {
                    this.$message({
                        message: "设置成功",
                        type: "success"
                    })
                    this.showIntroduce = false;
                    this.getList();
                } else {
                    this.$message.error(err.message)
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        /**@method 设置介绍 */
        setIntroduceFun(row) {
            this.showIntroduce = true;
            this.introduce = {
                levelId: row.levelId,
                content: row.content,
            };
        },
        /**@method 提交关联 */
        relationSubmit() {
            let { linkId, levelId, memberLevelId } = this.relationData;
            let params = { memberLevelId, levelId, linkId };
            if (!linkId && !levelId) {
                this.$message({
                    message: "关联等级成功",
                    type: "success"
                })
                return;
            }
            if (linkId && !levelId) {
                params.isDel = 1;
            }
            setAssociationMemberLevelLink(params).then(res => {
                if (res.code === 200) {
                    this.$message({
                        message: "关联等级成功",
                        type: "success"
                    })
                    this.showRelation = false;
                    this.getList();
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        /**@method 关联分销会员等级 */
        relationFun(row) {
            this.showRelation = true;
            this.relationData = {
                levelName: row.levelName,
                linkId: row.linkId,
                levelId: row.linkLevelId,
                memberLevelId: row.levelId
            };
        },
        /**@method 获取初始化数据 */
        getInitData() {
            getByDictType({
                dictType: "member_job", status: 0
            }).then(res => {
                if (res.code === 200) {
                    let memberJob = {};
                    for (let row of res.data.sysDictDataList) {
                        memberJob[row.dictValue] = row.dictLabel;
                    }
                    this.memberJob = memberJob;
                }
            })
            selectAllMemberLevel({
                isPage: 0,
                companyId: this.userInfo.companyId
            }, 9).then(res => {
                if (res.data) {
                    this.linkLevelData = res.data;
                }
            })
            return getByDictType({ dictType: "member_level", status: 0 }).then(memberLevelRes => {
                if (memberLevelRes.code === 200) {
                    let memberLevel = {};
                    for (let row of memberLevelRes.data.sysDictDataList) {
                        memberLevel[row.dictValue] = row.dictLabel;
                    }
                    this.memberLevel = memberLevel;
                }
            })
        },
        /**@method 搜索 */
        searchFun() { this.getList() },
        /**@method 获取列表 */
        async getList() {
            let params = {
                levelType: this.contentType,
                associationId: this.associationId
            };
            if (this.searchData.name) {
                params.levelName = this.searchData.name
            }
            try {
                this.loading = true;
                let res = await getAssociationMemberLevelList(params)
                this.loading = false;

                const { data } = res;
                let tableData = [];
                for (let row of data) {
                    row.statusTitle = this.statusData[row.status]
                    tableData.push(row);
                }
                this.tableData = tableData;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        handleClick() {
            this.getList();
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateTypeModel(val) {
            this.typeShowModel = true;
            this.typeModelTitle = "编辑会员类型"
            this.formType = "update";
            this.updateId = val.levelId;
            this.typeFormData = {
                levelName: val.levelName,
                memberLevel: String(val.memberLevel),
                levelSort: val.levelSort,
                freeIntoShop: val.freeIntoShop,
                freeIntoCompany: val.freeIntoCompany,
            }
        },
        /**@method 新增会员类型 */
        showAddTypeModel() {
            this.typeShowModel = true;
            this.typeModelTitle = "添加会员类型"
            this.formType = "add";
            this.updateId = 0;
            this.typeFormData = {
                levelName: "",
                memberLevel: "",
                levelSort: 0,
                freeIntoShop: false,
                freeIntoCompany: false,
            }
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateJobModel(val) {
            this.jobShowModel = true;
            this.jobModelTitle = "编辑会员职务"
            this.formType = "update";
            this.updateId = val.levelId;
            this.jobFormData = {
                levelName: val.levelName,
                memberLevel: String(val.memberLevel),
                activityFreeNum: val.activityFreeNum ? val.activityFreeNum : 0,
                levelSort: val.levelSort,
                content: val.content,
                pointGive: val.pointGive,
                pointShare: val.pointShare,
            }
        },
        /**@method 删除 */
        delFun(id) {
            let parmas = {
                levelId: id,
                isDel: 1
            }
            setAssociationMemberLevel(parmas).then(res => {
                if (res.code === 200) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    })
                    this.getList();
                } else {
                    this.$message.error(err.message)
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        /**@method 禁用/启用 */
        disableFun(row, status) {
            let parmas = {
                levelId: row.levelId,
                status: status
            }
            setAssociationMemberLevel(parmas).then(res => {
                if (res.code === 200) {
                } else {
                    this.$message.error(err.message)
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        /**@method 添加会员职务 */
        showAddJobModel() {
            this.jobShowModel = true;
            this.jobModelTitle = "添加会员职务"
            this.formType = "add";
            this.updateId = 0;
            this.jobFormData = {
                levelName: "",
                memberLevel: "",
                activityFreeNum: 0,
                levelSort: 0,
                content: "",
                pointGive: 0,
                pointShare: 0,
            }
        },
        /**@method 修改添加会员等级 */
        typeSubmit() {
            this.$refs.typeFormData.validate((valid) => {
                if (valid) {
                    let parmas = {
                        ...this.typeFormData,
                        associationId: this.associationId,
                        levelType: this.contentType
                    }
                    let message = "新增成功"
                    if (this.formType === 'update') {
                        parmas.levelId = this.updateId
                        message = "编辑成功";
                    }
                    setAssociationMemberLevel(parmas).then(res => {
                        if (res.code === 200) {
                            this.$message({
                                message: message,
                                type: "success"
                            })
                            this.typeShowModel = false;
                            this.getList();
                        } else {
                            this.$message.error(err.message)
                        }
                    }).catch(err => {
                        if (err.code === 201) {
                            this.$message.error(err.message)
                        }
                    })
                }
            });
        },
        /**@method 修改添加会员等级 */
        jobSubmit() {
            this.$refs.jobFormData.validate((valid) => {
                if (valid) {
                    let parmas = {
                        ...this.jobFormData,
                        associationId: this.associationId,
                        levelType: this.contentType
                    }
                    let message = "新增成功"
                    if (this.formType === 'update') {
                        parmas.levelId = this.updateId
                        message = "编辑成功";
                    }
                    setAssociationMemberLevel(parmas).then(res => {
                        if (res.code === 200) {
                            this.$message({
                                message: message,
                                type: "success"
                            })
                            this.jobShowModel = false;
                            this.getList();
                        } else {
                            this.$message.error(err.message)
                        }
                    }).catch(err => {
                        if (err.code === 201) {
                            this.$message.error(err.message)
                        }
                    })
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>